import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DownloadPage.css";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { apiUrl } from "../../api/apiUrl";

export const DownloadPage = () => {
  const [customerIdData, setCustomerIdData] = useState("");
  const [myOrdersData, setMyOrdersData] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  console.log("selectedOrder", selectedOrder);
  const fetchCustomerData = async () => {
    try {
      if (customerIdData && customerIdData._id) {
        const cusID = customerIdData._id;
        const response = await axios.get(
          `${apiUrl}/customer/${cusID}`
        );
        const data = response.data;
        setMyOrdersData(data.myOrders);
        console.log("myOrders", data.myOrders);
      }
    } catch (error) {
      console.error("Failed to fetch customer data1: Error", error.message);
    }
  };
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("customer-user"));
    setCustomerIdData(storedData);
  }, []);

  useEffect(() => {
    if (customerIdData && customerIdData._id) {
      fetchCustomerData();
    }
  }, [customerIdData]);

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
  };

  const handleCloseDetails = () => {
    setSelectedOrder(null);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "";
    }

    // Format the date to "yyyy-MM-dd HH:mm"
    const formattedDate = date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedDate;
  };

  const formatDateOnly = (dateString) => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "";
    }

    // Format the date to "yyyy-MM-dd HH:mm"
    const formattedDate = date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const upcoming = true

  return (
    <div style={{ backgroundColor: "f0f0f0" }}>
      <TopBar />
      <div className="myAccountMainContainer">
        <SideMyAccount />
        {
          upcoming ?
            <div className="upcoming_container_wrapper">
            <span style={{fontSize:"2rem"}}>
              Upcoming....
            </span>
            </div>
            :
            <div style={{ width: "100%" }}>
              <p style={{ fontSize: "150%", marginLeft: 20, fontWeight: "bold" }}>
                Invoice Details
              </p>
              <div>
                <Table style={{ margin: "auto", width: "90%" }}>
                  <Thead>
                    <Tr>
                      <Th
                        style={{
                          border: "1px solid black",
                          backgroundColor: "white",
                          height: 50,
                          textAlign: "left",
                          paddingLeft: 15,
                        }}
                      >
                        ORDER
                      </Th>
                      <Th
                        style={{
                          border: "1px solid black",
                          backgroundColor: "white",
                          height: 50,
                          padding: 15,
                        }}
                      >
                        PRODUCT
                      </Th>
                      <Th
                        style={{
                          border: "1px solid black",
                          backgroundColor: "white",
                          height: 50,
                          padding: 15,
                        }}
                      >
                        DATE
                      </Th>
                      <Th
                        style={{
                          border: "1px solid black",
                          backgroundColor: "white",
                          height: 50,
                          padding: 15,
                        }}
                      >
                        TOTAL
                      </Th>
                      <Th
                        style={{
                          border: "1px solid black",
                          backgroundColor: "white",
                          height: 50,
                          padding: 15,
                        }}
                      >
                        ACTIONS
                      </Th>
                    </Tr>
                  </Thead>
                  {Array.isArray(myOrdersData) && myOrdersData.length > 0 ? (
                    myOrdersData.map((order) => (
                      <Tbody key={order._id}>
                        {order.product_name.map((product, index) => (
                          <Tr key={product._id}>
                            <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                              #{order._id.slice(-4)}{" "}
                            </Td>
                            <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                              {product.product_name} - {product.category}
                            </Td>
                            <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                              {formatDate(order.servicing_date)}
                            </Td>
                            <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                              ₹ {order.final_total}/-
                            </Td>
                            <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                              <button onClick={() => handleViewDetails(order)}>
                                View Details
                              </button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    ))
                  ) : (
                    <p>No orders available</p>
                  )}
                </Table>
              </div>

              {/* Modal to show detailed product information */}
              {selectedOrder && (
                <div className="modal-overlay">
                  <div className="modal">
                    <h2 style={{ marginBottom: "15px" }}>Product Details</h2>

                    {/* Left Section */}
                    <div>
                      <p>
                        <strong>Product Name:</strong>{" "}
                        {selectedOrder.product_name[0].product_name}
                      </p>
                      <p>
                        <strong>Category:</strong>{" "}
                        {selectedOrder.product_name[0].category}
                      </p>
                      {/* Add other fields for the left section */}
                    </div>

                    {/* Right Section */}
                    <div>
                      <p>
                        <strong>Status:</strong> Boooking Confirm
                      </p>
                      <p>
                        <strong>Model:</strong> {selectedOrder.make_model} -{" "}
                        {selectedOrder.vehicle_model}
                      </p>

                      <p>
                        <strong>Vehicle No.</strong> {selectedOrder.vehicle_no}
                      </p>
                      <p>
                        <strong>Total:</strong> ₹{" "}
                        {selectedOrder.product_name[0].total}
                      </p>
                      {/* Add other fields for the right section */}
                    </div>
                    <p>
                      <strong>City:</strong> {selectedOrder.city}
                    </p>
                    <p>
                      <strong>Address:</strong> {selectedOrder.location}
                    </p>
                    <p>
                      <strong>Order Time:</strong>{" "}
                      {formatDate(selectedOrder.product_name[0].createdAt)}
                    </p>
                    <p>
                      <strong>Pickup Time:</strong>{" "}
                      {formatDateOnly(selectedOrder.servicing_date)} Time:{" "}
                      {selectedOrder.servicing_time}
                    </p>
                    {/* <p>
                  <strong>Description:</strong>{" "}
                  <select style={{marginTop:10}}>
                    {selectedOrder.product_name[0].description.map((desc, index) => (
                      <option key={index}>{desc}</option>
                    ))}
                  </select>
                </p> */}

                    <button
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      onClick={handleCloseDetails}
                    >
                      Close Details
                    </button>
                  </div>
                </div>
              )} */
            </div>
        }
      </div>
      <FooterHomeSecond />
    </div>
  );
};
