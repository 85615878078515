import React, { useEffect, useState } from "react";
import axios from "axios";
import "./myVehicles.css";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";
import { Link, useNavigate } from "react-router-dom";
import bike from "../../assests/bike.png";
import car from "../../assests/car.png";
import { useSelector } from "react-redux";
import { apiUrl } from "../../api/apiUrl";

export const MyVehicles = () => {
  const [vehicleData, setVehicleData] = useState([]);

  const getVehicleData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/customer/fetch/current/customer/vehicles`,
        {
          withCredentials: true
        }
      );
      console.log("hi nice", response)
      setVehicleData(response.data.customerVehicles);
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
    }
  };

  useEffect(() => {
    getVehicleData()
  }, []);

  const deleteData = async (id) => {
    console.log(id)
    const confirmed = window.confirm(
      "Are you sure you want to delete this vehicle?"
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await axios.delete(
        `${apiUrl}/customer/vehicles/${id}`,
        {
          withCredentials: true
        }
      );

      if (response.data.success) {
        setVehicleData(response.data.vehicles);
        alert(response.data.message)
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div style={{ color: "black" }}>
      <TopBar />
      <div className="myAccountMainContainer">
        <SideMyAccount />
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: "150%", marginLeft: 20, fontWeight: "bold" }}>
            Vehicles Details
          </p>
          <div className="vehicles-grid">
            {vehicleData.length > 0 && vehicleData.map((item) => (
              <div className="vehicle-card" key={item._id}>
                <ul>
                  {/* <li className="heading-text">
                      Vehicle Type: {item.vehicle_type}
                    </li> */}
                  {item.vehicle_type === "Bike" ? (
                    <div
                      style={{
                        justifyContent: "center",
                        height: "80%",
                        width: "80%",
                        margin: "auto",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={bike}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        justifyContent: "center",
                        height: "80%",
                        width: "80%",
                        margin: "auto",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={car}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  )}
                  <li className="heading-text">
                    <b> Make Model: </b>
                    {item.make_model}
                  </li>
                  <li className="heading-text">
                    <b>Variant: </b>
                    {item.variant}
                  </li>
                  <li className="heading-text">
                    <b> Fuel Type: </b> {item.fuel_type}
                  </li>


                  {item.vehicle_type === "Bike" ? (
                    <li className="heading-text">
                      <b>Bike CC: </b>
                      {item.bike_cc}
                    </li>
                  ) : (
                    <li className="heading-text">
                      <b>Car Type: </b>
                      {item.car_type}
                    </li>
                  )}

                  {/* <li className="heading-text">
                      Vehicle Image: {item.vehicle_image}
                    </li> */}
                  <li className="heading-text">
                    <b>Number: </b>
                    {item.reg_number}
                  </li>
                  {/* <li className="heading-text">ID: #_{item._id.slice(-4)}</li> */}
                </ul>
                <button onClick={() => deleteData(item._id)}>Delete</button>
              </div>
            ))
            }
            <div className="addMyvehicleNewBox">
              {/* <button>Add Vehicle</button> */}
              <Link to={"/addmyvehiclenew"} className="addMyvehicleNew">
                Add vehicle
              </Link>
            </div>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
