import React, { useEffect, useState } from "react";
import "./topbar.css";
import image from "../assests/main-background.png";
import logo from "../assests/gmi-logo.png";
import menu from "../assests/menu.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedInCustomerData, setUserAuthenticated } from "../features/customerSlice.features";
import { apiUrl } from "../api/apiUrl";

export const TopBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch()
  const { loggedInCutomerData } = useSelector((state) => state.customer)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  var [customerIdData, setCustomerIdData] = useState("");

  const fetchCurrentCustomerData = async () => {
    try {
      // if (isUserAuthenticated) {
      const response = await axios.get(
        `${apiUrl}/customer/fetch/single`,
        { withCredentials: true }
      );

      // console.log("nice in tobar",response)

      if (response.data.success) {
        dispatch(setLoggedInCustomerData(response.data.customer))
      }
    } catch (error) {
      console.log("Error retrieving data from storage:", error);
    }
  };

  useEffect(() => {
    if (Object.keys(loggedInCutomerData).length !== 0 && loggedInCutomerData.constructor === Object) {
      setCustomerIdData(loggedInCutomerData)
    } else {
      fetchCurrentCustomerData()
    }
  }, [loggedInCutomerData]);

  const handleLogout = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to log out?")) {
      try {
        const { data } = await axios.post(
          `${apiUrl}/customer/logout`,
          {},
          { withCredentials: true }
        )

        if (data.success) {
          dispatch(setUserAuthenticated(data.isLoggedIn))
          dispatch(setLoggedInCustomerData({}))
          navigation("/login");
        }
      } catch (error) {
        alert("Error while logout !!")
      }
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (window.confirm("You Need to Log in First?")) {
      navigation("/login");
    }
  };

  ///// animation >>>>>>>>>>
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    // Use setTimeout to delay setting animationComplete to true
    // This will trigger the animation after a short delay
    setTimeout(() => {
      setAnimationComplete(true);
    }, 100);
  }, []);

  ///// animation  <<<<<<<<<<<<<<<<<<<<<

  return (
    <div
      className={`topbox ${menuOpen ? "menu-open" : ""}`}
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
        height: "auto",
        // maxWidth: "100%",
        // border: "5px solid red",
      }}
    >
      {/* <div className="topbarContainer">
        <div>
          <Link to={"/"}>
            <img className="imgLogo" src={logo} alt="Italian Trulli" />
          </Link>
        </div>

        <img
          onClick={toggleMenu}
          className="menuLogo"
          src={menu}
          alt="Italian Trulli"
        />
      </div> */}

      <div className="topbarContainer">
        <div
          className={`leftDiv ${animationComplete ? "leftDivAnimation" : ""}`}
        >
          <Link to={"/"}>
            <img className="imgLogo" src={logo} alt="Logo" />
          </Link>
        </div>

        <div
          className={`rightDiv ${animationComplete ? "rightDivAnimation" : ""}`}
        >
          <img
            onClick={toggleMenu}
            className="menuLogo"
            src={menu}
            alt="Menu"
          />
        </div>
      </div>

      <nav className="side-menu">
        <button onClick={toggleMenu} className="close-button">
          ✕
        </button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 30,
            marginTop: 30,
            gap: 15,
            // border: "1px solid red",
            width: "100%",
          }}
        >
          {/* <Link to={"/about-us"} className="otherLinks">
            About us
          </Link> */}
          <Link to={"/contact-us"} className="otherLinks">
            Contact us
          </Link>
          {customerIdData ? (
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
              <Link to={"/Subscription"} className="otherLinks">
                My Subscription
              </Link>
              <Link to={"/cart"} className="otherLinks">
                Cart
              </Link>
              <Link to={"/addmyvehiclenew"} className="otherLinks">
                Add vehicle
              </Link>
              <Link to={"/my-account"} className="otherLinks">
                My account
              </Link>
              <Link onClick={handleLogout} className="loginBtn">
                Log Out
              </Link>

              {/* <Link to={"/MaintenancePage"} className="loginBtn">
                MaintenancePage
              </Link> */}

              {/* Testing >>>>>>>>>>>>>>>>> */}
              {/* <Link to={"/Test"} className="loginBtn">
                Test
              </Link> */}
            </div>
          ) : (
            <div style={{ marginTop: 10 }}>
              <Link
                onClick={handleLogin}
                className="loginBtn"
                style={{ padding: "3% 60% 3% 4%" }}
              >
                Log in
              </Link>
            </div>
          )}
        </div>
      </nav>

      <div className="centeredContent">
        <h1 className="titleText">
          Our Experienced Technicians are just a call away<br></br><a href="tel:9960445033" style={{ color: "green" }}>+91-9960445033</a>
        </h1>
      </div>
    </div>
  );
};
