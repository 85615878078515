import React, { useState, useEffect } from "react";
import axios from "axios";
import { AllRoutes } from "./AllRoutes";
import "./App.css";
import maintenancePage from "./assests/maintenancePage.png";
import { setUserAuthenticated } from "./features/customerSlice.features";
import { useDispatch } from "react-redux";
import { apiUrl } from "./api/apiUrl";

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const dispatch = useDispatch()

  /// maintenance page
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const checkIsUserLoggedIn = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/customer/check/logged/in`,
        {},
        { withCredentials: true }
      )
      console.log(data)
      if (data.isCustomerLoggedIn) {
        dispatch(setUserAuthenticated(data.isCustomerLoggedIn))
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    checkIsUserLoggedIn()
  }, [dispatch])

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isMaintenance ? (
        <div
          className="fullscreen-image"
          style={{
            width: windowDimensions.width,
            height: windowDimensions.height,
          }}
        >
          <h1
            style={{
              textAlign: "center",
              border: "1px solid transparent",
            }}
          >
            Maintenance Page
          </h1>
          <img
            src={maintenancePage}
            alt="maintenancePage"
            className="fullscreen-image__img"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      ) : (
        <AllRoutes />
      )}
    </div>
  );
}

export default App;
