import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { HomePage } from "./Pages/HomePage/HomePage";
import { Shop } from "./Pages/Shop/Shop";
import { ProductDetails } from "./Pages/ProductDetails/ProductDetails";
import { TermAndCondition } from "./Pages/TermAndCondition/TermAndCondition";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy/PrivacyPolicy";
import { FAQPage } from "./Pages/FAQPage/FAQPage";
import { RegisterLogin } from "./Pages/RegisterLogin/RegisterLogin";
import { CartPage } from "./components/CartPage/CartPage";
import { Checkout } from "./components/Checkout/Checkout";
import { OrderConfirmation } from "./components/orderConfirmation/OrderConfirmation";
import { MyAccount } from "./Pages/MyAccount/MyAccount";
import { OrderPage } from "./Pages/MyAccount/OrderPage";
import { AddressPage } from "./Pages/MyAccount/AddressPage";
import { DownloadPage } from "./Pages/MyAccount/DownloadPage";
import { EditAccount } from "./Pages/MyAccount/EditAccount";
import { ProductCategory } from "./Pages/ProductCategory/ProductCategory";
import { TipFact1 } from "./Pages/TipFactPages/TipFact1";
import { TipFact2 } from "./Pages/TipFactPages/TipFact2";
import { TipFact3 } from "./Pages/TipFactPages/TipFact3";
import { TipFact4 } from "./Pages/TipFactPages/TipFact4";
import { TipFact5 } from "./Pages/TipFactPages/TipFact5";
import { AboutPage } from "./Pages/AboutPage/AboutPage";
import { ContactPage } from "./Pages/ContactPage/ContactPage";
import { CareerPage } from "./Pages/CareerPage/CareerPage";
import { ConnectWithUs } from "./components/InfoGMI/ConnectWithUs";
import { EarnWithUs } from "./components/InfoGMI/EarnWithUs";
import { TestSingleProduct } from "./components/SingleProduct/TestSingleProduct";
import { Test } from "./components/Test";
import { AddMyVehicleNew } from "./components/addVehicle/AddMyVehicleNew";
import { Subscription } from "./Pages/SubscriptionPage/Subscription";
import { MaintenancePage } from "./Pages/maintenancePage/MaintenancePage";
import { MyVehicles } from "./components/myVehicles/MyVehicles";
import { useSelector } from "react-redux";

export const AllRoutes = () => {
  const { isUserAuthenticated } = useSelector((state) => state.customer);

  return (
    <>
      {/* Protected Routes */}
      {isUserAuthenticated ? (
        <>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/terms-and-conditions" element={<TermAndCondition />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<FAQPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order-received" element={<OrderConfirmation />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/my-account/orders" element={<OrderPage />} />
            <Route path="/addmyvehiclenew" element={<AddMyVehicleNew />} />
            <Route path="/my-account/downloads" element={<DownloadPage />} />
            <Route path="/my-account/edit-address" element={<AddressPage />} />
            <Route path="/my-account/edit-account" element={<EditAccount />} />
            <Route path="/productcategory/bike" element={<ProductCategory />} />
            <Route path="/productcategory/car" element={<ProductCategory />} />
            <Route path="/the-importance-of-regular-vehicle-servicing-why-you-shouldnt-skip-it" element={<TipFact1 />} />
            <Route path="/the-impact-of-seasonal-changes-on-your-vehicle-how-to-prepare-for-it" element={<TipFact2 />} />
            <Route path="/the-top-10-signs-that-your-vehicle-needs-servicing" element={<TipFact3 />} />
            <Route path="/what-happens-if-i-dont-service-my-vehicle" element={<TipFact4 />} />
            <Route path="/difference-between-local-servicing-centres-and-greasemonkey-authorised-servicing-centres" element={<TipFact5 />} />
            <Route path="/connect" element={<ConnectWithUs />} />
            <Route path="/earn" element={<EarnWithUs />} />
            <Route path="/TestSingleProduct" element={<TestSingleProduct />} />
            <Route path="/Subscription" element={<Subscription />} />
            <Route path="/MaintenancePage" element={<MaintenancePage />} />
            <Route path="/myvehicles" element={<MyVehicles />} />
            <Route path="/Test" element={<Test />} />
            <Route path="/contact-us" element={<ContactPage />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="*" element={<RegisterLogin />} />
        </Routes>
      )}
    </>
  );
};
