import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TopBar } from "../../TopBar/TopBar";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import "./checkout.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setOrderPlacedData } from "../../features/cartSlice.features";
import { apiUrl } from "../../api/apiUrl";

export const Checkout = () => {
  const navigate = useNavigate();

  const { cartAmountDetails, selectedCartData } = useSelector((state) => state.cart);
  const { loggedInCutomerData } = useSelector((state) => state.customer)

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: selectedCartData.email,
    total: cartAmountDetails.finalTotal,
    finalTotal: cartAmountDetails.finalsubTotal,
    customerNumber: selectedCartData.phone_no,
    makeModel: selectedCartData.make_model,
    verifyMakeModel: selectedCartData.make_model ? true : false,
    vehicleType: selectedCartData.vehicle_type,
    registrationNumber: selectedCartData.reg_number,
    verifyRegistrationNumber: selectedCartData.reg_number ? true : false,
    city: selectedCartData.city,
    verifyTransmissionType: selectedCartData.vehicle_type === "Car",
    verifyBreakdown: false,
    verifyVehicleInsurance: false,
    address: loggedInCutomerData.address
  })

  // console.log("hi", cartAmountDetails)

  const fetchProductData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/products/${selectedCartData.selectedProductId}`
      );
      if (response.data) {
        setFormData((prevData) => {
          return {
            ...prevData,
            productsBuyed: [response.data]
          }
        })
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProductData()
  }, [selectedCartData.selectedProductId])


  const handleChange = (e) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [e.target.name]: e.target.value
      }
    })
  };

  console.log(formData)

  const handlePlaceOrder = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        `${apiUrl}/newmyorder/generate`,
        {
          orderData: formData,
          cartId: selectedCartData._id
        },
        {
          withCredentials: true
        }
      )

      console.log(response)
      if (response.data.success) {
        dispatch(setOrderPlacedData(response.data.order))
        alert(response.data.message)
        navigate("/order-received");
      }
    } catch (error) {
      alert("Server error while placing order !!")
    }
  };

  const handleToggleCheckBoxInputFields = (key, message) => {
    if (window.confirm(message)) {
      setFormData((prev) => {
        const updatedState = {
          ...prev,
          [key]: !prev[key],
        }

        // Reset `vehicleInsuranceStatus` only if one of the specified conditions is false
        if (!updatedState.verifyMakeModel || !updatedState.verifyRegistrationNumber) {
          updatedState.vehicleInsuranceStatus = ""; // Reset radio buttons
        }

        if (key === "verifyVehicleInsurance" && updatedState.verifyVehicleInsurance === false) {
          updatedState.vehicleInsuranceStatus = ""
        }

        return updatedState;

      });
    }
  };

  const handleInsuranceStatusRadioChange = (status, message) => {
    if (formData.verifyMakeModel === false || formData.verifyRegistrationNumber === false) {
      window.alert("First confirm vehicle make model and registration number check box!")
    } else if (window.confirm(message)) {
      setFormData((prev) => ({
        ...prev,
        vehicleInsuranceStatus: status,
      }));
    }
  };

  const timeSlots = [
    "10:00 AM - 12:00 PM",
    "12:00 PM - 02:00 PM",
    "02:00 PM - 04:00 PM",
    "04:00 PM - 06:00 PM",
    "06:00 PM - 08:30 PM",
  ];


  const handleSelectPickupLocation = (e) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        pickupLocation: e.target.value
      }
    })
  }

  const handleSelectDropLocation = (e) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        dropLocation: e.target.value
      }
    })
  }

  return (
    <div style={{ backgroundColor: "#F1F0F1" }}>
      <TopBar />
      <div className="containerCheckout">
        <div className="billing-details">
          <h3>Billing details</h3>

          <div className="checkoutDataBox">
            <div className="fieldBox">
              <div className="fieldTitle"><p>Full name</p></div>
              <div className="inputField"><p>{selectedCartData.name}</p></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Email address</p></div>
              <div className="inputField"><p>{selectedCartData.email}</p></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Vehicle type</p></div>
              <div className="inputField"><p>{selectedCartData.vehicle_type}</p></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Vehicle make & model</p></div>
              <div className="inputField"><p>{selectedCartData.make_model}</p></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Vehicle Register No.</p></div>
              <div className="inputField"><p>{selectedCartData.reg_number}</p></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>City</p></div>
              <div className="inputField"><p>{selectedCartData.city}</p></div>
            </div>
            <div className="fieldBox">
              <div className="fieldTitle"><p>Address</p></div>
              <div className="inputField"><p>{loggedInCutomerData.address}</p></div>
            </div>
          </div>

          <div className="vehicle_condition_wrapper">
            <div>
              <label>Vehicle Condition</label>
              <div className="my_lable_input_group">
                <span className="input_label">Vehicle Breakdown</span>
                <input
                  type="checkbox"
                  name="verifyBreakdown"
                  checked={formData.verifyBreakdown}
                  onClick={() => handleToggleCheckBoxInputFields(
                    'verifyBreakdown',
                    'Are you sure you want to change the value of Vehicle Breakdown?'
                  )} />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div className="my_lable_input_group">
                <span>Vehicle Insurance</span>
                <input
                  type="checkbox"
                  name="verifyVehicleInsurance"
                  checked={formData.verifyVehicleInsurance}
                  onClick={() => handleToggleCheckBoxInputFields(
                    'verifyVehicleInsurance',
                    'Are you sure you want to change the value of Vehicle Under Insurance Servicing?'
                  )} />
              </div>
              {formData.verifyVehicleInsurance && (
                <div className="confirm_insurance_wrapper">
                  <div className="my_lable_input_group">
                    <input
                      type="radio"
                      name="vehicleInsuranceStatus"
                      value="valid"
                      checked={formData.vehicleInsuranceStatus === "valid"}
                      onClick={() => handleInsuranceStatusRadioChange(
                        'valid',
                        'Are you sure you want to change the value of Vehicle Insurance Status?'
                      )}
                    />
                    <span style={{ fontSize: '14px', color: '#555' }}>Valid</span>
                  </div>

                  <div className="my_lable_input_group">
                    <input
                      type="radio"
                      name="vehicleInsuranceStatus"
                      value="invalid"
                      checked={formData.vehicleInsuranceStatus === "invalid"}
                      onClick={() => handleInsuranceStatusRadioChange(
                        'invalid',
                        'Are you sure you want to change the value of Vehicle Insurance Status?'
                      )}
                      style={{ marginRight: '10px' }}
                    />
                    <span style={{ fontSize: '14px', color: '#555' }}>Invalid</span>
                  </div>
                </div>
              )}
            </div>

          </div>
          <div className="inputFieldDataBox">
            <label htmlFor="servicingDate">Date of servicing<span style={{ color: "red" }}>*</span></label>
            <div className="dataTimeBox">
              <input type="date" name="servicingDate" value={formData.servicingDate || ""} onChange={handleChange} placeholder="Servicing Date *" />
            </div>
            <div className="form-group" style={{ width: "92%" }}>
              <label>Select Time Slot</label>
              <select className="form-select" name="serviceTimeSlot" onChange={handleChange} value={formData?.serviceTimeSlot || ""}>
                <option value="" disabled>Select a time slot</option>
                {timeSlots?.map((slot, index) => (
                  <option key={index} value={slot}>{slot}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="expectedDeliveryDate">Expected Delivery Date<span style={{ color: "red" }}>*</span></label>
              <div className="dataTimeBox">
                <input type="date" name="expectedDeliveryDate" value={formData.expectedDeliveryDate || ""} onChange={handleChange} placeholder="Expected Servicing Date *" />
              </div>
            </div>
            {
              selectedCartData.vehicle_type === "Car" && <div className="form-group" style={{ width: "92%" }}>
                <label>Select Transmission Type</label>
                <select className="form-select" name="transmissionType" onChange={handleChange} value={formData?.transmissionType || ""}>
                  <option value="" disabled>Select Transmission Type</option>
                  <option value={"Manual"} onChange={handleChange}>Manual</option>
                  <option value={"Automatic"} onChange={handleChange}>Automatic</option>
                </select>
              </div>
            }

            {/* <label>Address<span style={{ color: "red" }}>*</span></label>
            <input type="text" value={formData?.address} name="address" onChange={handleChange} placeholder="Street address *" /> */}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Pickup Location<span style={{ color: "red" }}>*</span></label>
              <div style={{ display: "flex", gap: "0.2rem", marginTop: "0.5rem" }}>
                {
                  loggedInCutomerData?.otherAddress.map((curAddress) => {
                    return <div className="my_lable_input_group">
                      <input
                        type="radio"
                        name="pickupAddress"
                        value={curAddress.address}
                        checked={formData.pickupLocation === curAddress.address}
                        onClick={handleSelectPickupLocation}
                        style={{ marginRight: '10px' }}
                      />
                      <span style={{ fontSize: '14px', color: '#555' }}>{curAddress.address}</span>
                    </div>
                  })
                }
              </div>
              <input type="text" value={formData?.pickupLocation} name="pickupLocation" onChange={handleChange} placeholder="Pickup Location *" />
            </div>

            <div style={{ marginTop: "0.5rem" }}>
              <label>Drop Location<span style={{ color: "red" }}>*</span></label>
              <div style={{ display: "flex", gap: "0.2rem", marginTop: "0.5rem" }}>
                {
                  loggedInCutomerData?.otherAddress.map((curAddress) => {
                    return <div className="my_lable_input_group">
                      <input
                        type="radio"
                        name="dropAddress"
                        value={curAddress.address}
                        checked={formData.dropLocation === curAddress.address}
                        onClick={handleSelectDropLocation}
                        style={{ marginRight: '10px' }}
                      />
                      <span style={{ fontSize: '14px', color: '#555' }}>{curAddress.address}</span>
                    </div>
                  })
                }
              </div>
              <input type="text" value={formData?.dropLocation} name="dropLocation" onChange={handleChange} placeholder="Drop Location *" />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <label>State<span style={{ color: "red" }}>*</span></label>
              <input type="text" value={formData?.state} name="state" onChange={handleChange} placeholder="State *" />
            </div>

            <label>Pincode<span style={{ color: "red" }}>*</span></label>
            <input type="text" value={formData?.pincode} name="pincode" onChange={handleChange} placeholder="Pincode *" />

            <label>Alternate Mobile Number<span style={{ color: "red" }}>*</span></label>
            <input type="text" value={formData?.phone} name="alternateNumber" onChange={handleChange} placeholder="Alternate Mobile Number *" />

            <label>Note</label>
            <input type="text" value={formData?.note} name="note" onChange={handleChange} placeholder="Note *" />
          </div>
        </div>

        <div className="order">
          <h3>Your order</h3>
          <table style={{ backgroundColor: "white" }}>
            <thead>
              <tr><th>PRODUCT</th><th>SUBTOTAL</th></tr>
            </thead>
            <tbody>
              <>
                <tr><td>{selectedCartData.product_name}</td><td style={{ textAlign: "center" }}>₹ {cartAmountDetails.finalsubTotal}</td></tr>
                <tr><td>Discount</td><td style={{ textAlign: "center", color: "red" }}>-₹ {cartAmountDetails.discount}</td></tr>
                <tr>
                  <td style={{ fontWeight: "bold", display: "flex", flexDirection: "column", gap: "0.4rem" }}>
                    <p style={{ margin: 0 }}>
                      Amount
                    </p>
                    <p style={{ color: "red", textTransform: "lowercase", margin: 0, fontSize: "0.7rem" }}>"Please note, this is not the final total. Your final total will be calculated based on any additional items you select for your service."</p>
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "center" }}>₹ {cartAmountDetails.finalTotal}/-
                  </td></tr>
              </>
            </tbody>
          </table>

          <div className="textBox">
            <div className="radioBox1">
              <div className="radioBox">
                <input type="radio" checked readOnly />
                <p>Pay on Order Completion.</p>
              </div>
              <p>Pay on Order Completion with UPI, NEFT, IMPS, Credit Card, or Debit Card.</p>
            </div>
            <p>Your personal data will be used to process your order, support your experience, and for other purposes described in our privacy policy.</p>
            <div className="checkedBox">
              <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
              <span>I have read and agree to the website terms and conditions *</span>
            </div>
          </div>

          <button type="submit" onClick={handlePlaceOrder} className={`orderButton ${!isChecked ? "disabled" : ""}`} disabled={!isChecked || isLoading}>
            {isLoading ? "Processing..." : "Place Order"}
          </button>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
