import axios from "axios";
import "./SingleProduct.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bike from "../../assests/bike.png";
import car from "../../assests/car.png";
import { useDispatch } from "react-redux";
import { setSelectedProductDetails, setSelectedProductId } from "../../features/cartSlice.features";
import { apiUrl } from "../../api/apiUrl";

export const SingleProduct = ({ id }) => {
  const [selectedCity, setSelectedCity] = useState("");
  const [allVehiclesData, setAllVehiclesData] = useState([])
  const [customerId, setCustomerId] = useState("")
  const [productData, setProductData] = useState({})
  const [selectedVehicle, setSelectedVehicle] = useState({})
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [total, setTotal] = useState(0)
  const [dataToAddInCart, setDataToAddInCart] = useState({})
  const [activeContent, setActiveContent] = useState("")

  const [showSubPoints,setShowSubPoints] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  console.log(total)
  const fetchVehicleData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/customer/fetch/single`,
        {
          withCredentials: true
        }
      );

      if (response.data.success) {
        setAllVehiclesData(response.data.customer.vehicles || []);
        setDataToAddInCart((prevData) => {
          return {
            ...prevData,
            CXID: response.data.customer.CXID,
            name: response.data.customer.name,
            email: response.data.customer.email,
            phone_no: response.data.customer.phone_no,
            city: response.data.customer.city,
          }
        })
      }
    } catch (err) {
      console.error(err);
    }
  }


  const fetchProductData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/products/${id}`
      );
      setProductData(response.data);
      setDataToAddInCart((prevData) => {
        return {
          ...prevData,
          product_name: response.data.product_name,
          image: response.data.image,
          price_tag: response.data.price_tag,
          selectedProductId:response.data._id
        }
      })
      dispatch(setSelectedProductDetails(response.data))
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectCity = (e) => {
    setSelectedCity(e.target.value)
  }

  const handleSetTotalValueForCar = () => {
    if (selectedCity) {
      const city = selectedCity.toLowerCase()
      if (selectedVehicle.vehicle_type === "Car") {
        const selectedProduct = productData[city][0].car_segment

        const filteredObject = selectedProduct.find(obj => obj.hasOwnProperty(selectedVehicle.car_type))
        setTotal(filteredObject[selectedVehicle.car_type])
        setIsButtonDisabled(false)
      }
    }
  }

  const handleSetTotalValueForBike = () => {
    if (selectedCity) {
      const city = selectedCity.toLowerCase()

      if (selectedVehicle.vehicle_type === "Bike") {
        const selectedProduct = productData[city][0].bike_segment

        let selectedData

        let bike_cc = selectedVehicle.bike_cc

        if (bike_cc >= 90 && bike_cc < 150) {
          selectedData = selectedProduct[0];
        } else if (bike_cc >= 150 && bike_cc <= 200) {
          selectedData = selectedProduct[1];
        } else if (bike_cc > 200) {
          selectedData = selectedProduct[2];
        }

        if (selectedData) {

          const keys = Object.keys(selectedData);
          const value = keys.length > 0 ? selectedData[keys[0]] : undefined;

          setTotal(value !== undefined ? value : 0);
        }

        if (selectedData) {
          const keys = Object.keys(selectedData);
          const value = keys.length > 0 ? selectedData[keys[0]] : undefined;
          setTotal(value !== undefined ? value : 0);
          setIsButtonDisabled(false)
        }

      }
    }
  }

  const setDataToAddInCartHandler = () => {
    setDataToAddInCart((prevData) => {
      return {
        ...prevData,
        total: total,
        bike_cc: selectedVehicle.bike_cc,
        car_segment: selectedVehicle.car_type,
        city: selectedCity,
        make_model:selectedVehicle.make_model,
        vehicle_type:selectedVehicle.vehicle_type,
        reg_number:selectedVehicle.reg_number
      }
    })
  }

  useEffect(() => {
    if (selectedVehicle) {
      if (selectedVehicle.bike_cc === "0" && selectedVehicle.car_type !== "") {
        handleSetTotalValueForCar();
        setDataToAddInCartHandler()
      }
      else {
        handleSetTotalValueForBike()
        setDataToAddInCartHandler()
      }
    }
  }, [selectedVehicle, selectedCity, total])

  const handleSelectVehicle = (vehicleData) => {
    setSelectedVehicle(vehicleData)
  }

  const handleAddToCart = async () => {
    setIsLoadingBtn(true);
    try {
      dispatch(setSelectedProductId(id))
      if (true) {
        const response = await axios.put(
          `${apiUrl}/customer/cart`,
          dataToAddInCart,
          {
            withCredentials:true
          }
        );

        if (response.data.success) {
          alert(response.data.message)
          navigate("/cart");
        }

      }
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
    setIsLoadingBtn(false);
  };

  const handleShowDescription = () => {
    setActiveContent("Content 1");
    // setActiveButton("Button 1");
  }

  const handleShowCC = () => {
    setActiveContent("Content 2");
    // setActiveButton("Button 2");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProductData()
    fetchVehicleData()
  }, []);

  return (
    <div>
      <div className="detailContainer">
        <div className="prodDetailBox">
          <div className="proImg">
            <img
              src={
                productData.image
                  ? require(`../../assests/${productData.image}`)
                  : "Loading Image..."
              }
              alt="Product Image..."
              className="singleProductImg"
            />
          </div>
          <div className="productRight">
            <div>
              {/* <p className="productName">{product.product_name}</p> */}
            </div>

            <div className="cityBox">
              <p className="productNameText">
                Select City <span style={{ color: "red" }}>*</span>
              </p>

              <select value={selectedCity} onChange={handleSelectCity}>
                <option value="">Select One</option>
                <option value="Nagpur">Nagpur</option>
                <option value="Pune">Pune</option>
              </select>
            </div>

            <div className="vegicleBox">
              <div>
                <p className="productNameText">
                  Select Vehicle <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              {/* item.vehicle_type === product.category.split(" ")[0] */}
              <div className="myVehicleList">
                {allVehiclesData
                  .filter(
                    (item) =>
                      item.vehicle_type === productData.category
                  )
                  .map((item) => (
                    <div
                      key={item._id}
                      onClick={() => handleSelectVehicle(item)}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                        width: "100%",
                        border: "1px solid #ddd",
                        cursor: "pointer",
                        borderRadius: 10,
                        backgroundColor:
                          selectedVehicle.reg_number === item.reg_number
                            ? "rgb(49,86,159)"
                            : "white",
                        color: selectedVehicle.reg_number === item.reg_number ? "white" : "black",
                      }}
                    >
                      <div
                        style={{
                          width: "35%",
                          height: "auto",
                          marginRight: "2%",
                          marginTop: "5%",
                          // border: "1px solid red",
                        }}
                      >
                        {productData.category.split(" ")[0] === "Bike" ? (
                          <img
                            src={bike}
                            style={{ height: "100%", width: "100%" }}
                          />
                        ) : (
                          <img
                            src={car}
                            style={{ height: "100%", width: "100%" }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: "55%",
                          height: 60,
                          marginTop: "5%",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "5%",
                            fontSize: 12,
                            color: "black",
                            fontWeight: "bold",
                            color:
                              selectedVehicle.reg_number === item.reg_number ? "white" : "black",
                          }}
                        >
                          {item.make_model}
                        </div>
                        <div
                          style={{
                            marginTop: "15%",
                            fontSize: 12,
                            color: "black",
                            fontWeight: "bold",
                            color:
                              selectedVehicle.reg_number === item.reg_number ? "white" : "black",
                          }}
                        >
                          {item.reg_number}
                        </div>
                      </div>
                    </div>
                  ))}
                <Link to={"/addmyvehiclenew"} className="addVehicleBtn">
                  Add vehicle+{" "}
                </Link>
              </div>
            </div>
            <p
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "rgb(46,85,162)",
              }}
            >
              ₹ {total}{" "}
            </p>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: 17 }}>
                <button
                  onClick={handleAddToCart}
                  disabled={isButtonDisabled}
                  style={{
                    textDecoration: "none",
                    padding: "10px 45px",
                    borderRadius: "10px",
                    color: "white",
                    fontSize: "90%",
                    backgroundColor: isButtonDisabled ? "grey" : " #fec601",
                    fontWeight: "bold",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer", // Optional: Change cursor
                  }}
                >
                  {isLoadingBtn ? "loading... " : "ADD TO CART"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="descShowBox">
          <div className="descAddBox">
            <div
              onClick={handleShowDescription}
              style={{
                borderTop:
                  activeContent === "Content 1"
                    ? "3px solid rgb(46,85,162)"
                    : "3px solid transparent",
                borderBottom:
                  activeContent === "Content 1"
                    ? "3px solid rgb(46,85,162)"
                    : "3px solid transparent",
                borderLeft:
                  activeContent === "Content 1"
                    ? "1px solid rgb(46,85,162)"
                    : "1px solid transparent",
                borderRight:
                  activeContent === "Content 1"
                    ? "1px solid rgb(46,85,162)"
                    : "1px solid transparent",
                fontSize: 20,
                color: "grey",
                fontWeight: "bold",
                textDecoration: "none",
                padding: "0 2%",
                cursor: "pointer",
              }}
            >
              <p className="descMainText">DESCRIPTION</p>
            </div>
            <div
              onClick={handleShowCC}
              style={{
                borderTop:
                  activeContent === "Content 2"
                    ? "3px solid rgb(46,85,162)"
                    : "3px solid transparent",
                borderBottom:
                  activeContent === "Content 2"
                    ? "3px solid rgb(46,85,162)"
                    : "3px solid transparent",
                borderLeft:
                  activeContent === "Content 2"
                    ? "1px solid rgb(46,85,162)"
                    : "1px solid transparent",
                borderRight:
                  activeContent === "Content 2"
                    ? "1px solid rgb(46,85,162)"
                    : "1px solid transparent",
                fontSize: 20,
                color: "grey",
                fontWeight: "bold",
                textDecoration: "none",
                padding: "0 2%",
                cursor: "pointer",
              }}
            >
              <p className="descMainText">ADDITIONAL INFORMATION</p>
            </div>
          </div>
          {activeContent === "Content 1" ? (
            <div>
              <p className="descriptionText">Description</p>
              <p className="descText">What will you get?</p>
              <div>
                <div style={{ lineHeight: "2" }}>
                  {productData.description &&
                    productData.description.map((item, index) => (
                      <div key={index}>
                        {item.main_point && ( // Check if main_point exists
                          <div className="descText">
                            <span>
                              <img
                                src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                                style={{ width: 20, height: 20 }}
                                className="rightMark"
                              />
                            </span>{" "}
                            {item.main_point}
                          </div>
                        )}
                        {showSubPoints &&
                          item.sub_points &&
                          item.sub_points.length > 0 && ( // Check if sub_points exists and is not empty
                            <div style={{ marginLeft: "20px" }}>
                              {item.sub_points.map((point, subIndex) => (
                                <div key={subIndex} className="descText">
                                  <span>
                                    <img
                                      src="https://s.w.org/images/core/emoji/14.0.0/svg/2705.svg"
                                      style={{ width: 20, height: 20 }}
                                      className="rightMark"
                                    />
                                  </span>{" "}
                                  {point}.
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                </div>
                <button
                  style={{ width: "100%", backgroundColor: "grey" }}
                onClick={() => setShowSubPoints(!showSubPoints)}
                >
                  {showSubPoints ? "Hide Sub Points" : "Show Sub Points"}
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className="descriptionText">Additional</p>
              <div className="ccBox">
                <div>
                  <p style={{ fontWeight: "bold" }} className="descText">
                    CC
                  </p>
                </div>
                <div>
                  <p className="descText">Upto 125, Upto 200</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

