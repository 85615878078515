import React, { useEffect, useState } from "react";
import "./SideMyAccount.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import addVehicle from "../../assests/addVehicle.png";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedInCustomerData, setUserAuthenticated } from "../../features/customerSlice.features";
import { apiUrl } from "../../api/apiUrl";

export const SideMyAccount = () => {
  const [image, setImage] = useState("");
  const dispatch = useDispatch()

  const { loggedInCutomerData } = useSelector((state) => state.customer);

  const handleLogout = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to log out?")) {
      try {
        const { data } = await axios.post(
          `${apiUrl}/customer/logout`,
          {},
          { withCredentials: true }
        )

        if (!data.isLoggedIn) {
          dispatch(setUserAuthenticated(data.isLoggedIn))
        }
      } catch (error) {

      }
    }
  };

  const fetchCurrentCustomerData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/customer/fetch/single`,
        { withCredentials: true }
      )

      if (response.data.success) {
        dispatch(setLoggedInCustomerData(response.data.customer))
      }
    } catch (error) {
      alert("server error while fetching current customer data !!")
    }
  }

  useEffect(() => {
    if (Object.keys(loggedInCutomerData).length === 0 && loggedInCutomerData.constructor !== Object) {
      fetchCurrentCustomerData()
    }
  }, [])


  return (
    <div className="sidebar">
      <div
        style={{
          borderBottom: "0.5px solid grey",
          width: "90%",
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
      >
        <div style={{ color: "black" }}>
          {loggedInCutomerData && image && (
            <img
              src={`https://gmi-server-1.onrender.com/${image
                .replace("public", "")
                .replace(/\\/g, "/")}`}
              alt="👽"
              style={{ width: 70, height: 70, borderRadius: "50%" }}
            />
          )}
        </div>
        <div>
          <div>
            <p style={{ fontWeight: "400", fontSize: "1.4rem" }}>Hi {loggedInCutomerData.name || ""}</p>
          </div>
        </div>
      </div>
      <div className="sidebar-menu">
        <Link to={"/my-account/edit-account"} className="btnLink">
          <p className="myAccountText">My Profile</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/266/266134.png"
            alt="details"
          />
        </Link>
        <Link to={"/my-account/orders"} className="btnLink">
          <p className="myAccountText">Orders</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/1170/1170678.png"
            alt="order"
          />
        </Link>
        <Link to={"/my-account/downloads"} className="btnLink">
          <p className="myAccountText">Invoices</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/860/860802.png"
            alt="download"
          />
        </Link>

        <Link to={"/myvehicles"} className="btnLink">
          <p className="myAccountText">My Vehicles</p>
          <img
            style={{ width: 30, height: 30, marginTop: 15, marginLeft: 30 }}
            src={addVehicle}
            alt="download"
          />
        </Link>

        <Link className="btnLink" onClick={handleLogout}>
          <p className="myAccountText">Log out</p>
          <img
            style={{ width: 25, height: 25, marginTop: 20 }}
            src="https://cdn-icons-png.flaticon.com/128/9070/9070510.png"
            alt="logout"
          />
        </Link>
      </div>
    </div>
  );
};
