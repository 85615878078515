import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "../features/customerSlice.features";
import cartReducer from "../features/cartSlice.features";

const store = configureStore({
    reducer: {
        customer: customerReducer,
        cart: cartReducer
    }
})

export default store;