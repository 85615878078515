import axios from "axios";
import React, { useEffect, useState } from "react";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";
import "./AddMyVehicleNew.css";
import { apiUrl } from "../../api/apiUrl";

export const AddMyVehicleNew = () => {
  const [reg_number, setRegNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  console.log("selectedData::", selectedData);

  useEffect(() => {
    getVehicleData();
  }, []);

  const handleUpdate = async () => {
    setIsLoading(true);

    if (!reg_number) {
      alert("Please fill Registration Number.");
      setIsLoading(false);
      return;
    } else if (!searchTerm) {
      alert("Please Select Vehicle Make & Model");
      setIsLoading(false);
      return;
    }

    const vehicleData = {
      vehicle_type: selectedData.vehicle_type,
      make_model: selectedData.make_model,
      variant: selectedData.variant,
      fuel_type: selectedData.fuel_type,
      bike_cc: selectedData.bike_cc,
      car_type: selectedData.car_type,
      vehicle_image: selectedData.vehicle_image,
      reg_number: reg_number,
    };
    console.log("vehicleData::", vehicleData);

    try {
      await axios.put(
        `${apiUrl}/customer/addvehicle`,
        vehicleData,
        {
          withCredentials: true
        }
      );
      setIsLoading(false);
      alert("Success", "Vehicle data added successfully.");
      window.history.back();
    } catch (error) {
      console.log("Error:", error);
      setIsLoading(false);
      alert("Error", "Failed to add vehicle data.");
    }
  };

  // .get("https://gmi-server-1.onrender.com/api/vehicles/allvehicles")
  const getVehicleData = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/vehicles/allvehicles`,
        {
          withCredentials: true
        }
      )
      console.log(data.vehiclesData)
      if (data.success) {
        setData(data.vehiclesData);
      }
    } catch (error) {
      alert("Error while fetching vehicles data")
    }
  };

  const filteredData = data.filter((vehicle) => {
    const makeModel = `${vehicle.make_model}  `.toLowerCase();
    return makeModel.includes(searchTerm.toLowerCase());
  });

  return (
    <div className="container">
      <TopBar />
      <div className="main-container">
        <div className="addVehicleContainer">
          <div className="addVehicleSubContainer">
            <div>
              <p className="title">Registration Information</p>
            </div>
            <div className="addVehicleNumBox">
              <div className="selectTextBox">
                <p className="vehicleNumText">
                  Registration Number <span style={{ color: "red" }}>*</span>
                </p>
              </div>
              <div className="selectBox">
                <input
                  style={{ color: "black", "::placeholder": { color: "grey" } }}
                  value={reg_number}
                  onChange={(e) => setRegNumber(e.target.value.toUpperCase())}
                  placeholder="e.g. (MH41 AZ 0000)"
                />
              </div>
            </div>
            <div className="addVehicleNumBox">
              <div className="selectTextBox">
                <p className="vehicleNumText">
                  Vehicle Make & Model <span style={{ color: "red" }}>*</span>
                </p>
              </div>

              <div className="selectBox">
                <input
                  type="text"
                  placeholder="Search by make or model"
                  value={
                    selectedData ? `${selectedData.make_model} ` : searchTerm
                  }
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setSelectedData(null);
                  }}
                />
                {searchTerm && !selectedData && (
                  <div className="scrollableContainer">
                    {filteredData.map((vehicle) => (
                      <div
                        key={vehicle._id}
                        onClick={() => setSelectedData(vehicle)}
                      >
                        <p
                          style={{ fontWeight: "90%" }}
                        >{`${vehicle.make_model} ${vehicle.variant}`}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="imageContainer">
            <img
              src="https://resources.turtlemint.com/images/registration.svg"
              alt="Registration"
              className="regimage"
            />
            <button
              className="submitButton2"
              onClick={handleUpdate}
              disabled={isLoading}
            >
              {isLoading ? "Uploading..." : "Add Vehicle"}
            </button>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
