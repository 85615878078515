import React, { useEffect, useState } from "react";
import { TopPage } from "../TopPage/TopPage";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import "./cartpage.css";
import axios from "axios";
import cancel from "../../assests/cancel.png";
import { Link, useNavigate } from "react-router-dom";
import { TopBar } from "../../TopBar/TopBar";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CouponCodeCard from "./CouponCodeCard";
import { useDispatch } from "react-redux";
import { setCartAmountDetails, setSelectedCartItem } from "../../features/cartSlice.features";
import { apiUrl } from "../../api/apiUrl";

export const CartPage = () => {
  let navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch()

  const [cusData, setcusData] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({})

  const calculateSubTotal = () => {
    if (!selectedProductId) return 0;
    const selectedItem = products.find((item) => item._id === selectedProductId);
    return selectedItem ? Number(selectedItem.total) : 0;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubTotal();
    const discountedTotal = subtotal - discount;
    return discountedTotal;
  };

  const handleSelection = (productId) => {
    const selectedItem = products.find((item) => item._id === productId);
    setSelectedProduct(selectedItem)
    setSelectedProductId(productId);
    setDiscount(0); // Reset discount when selecting a new product
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/customer/fetch/single`,
        {
          withCredentials: true
        }
      );

      if (response.data.success) {
        setProducts(response.data.customer.mycart);
      }

    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `${apiUrl}/customer/cart/${id}`,
        {
          withCredentials: true
        }
      );

      if (data.success) {
        alert("Cart item deleted successfully !")
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  console.log(products)


  const finalsubTotal = calculateSubTotal();


  const [couponCode, setCouponCode] = useState({
    couponCode: "",
    couponCodeId: ""
  });
  // console.log("couponCode:", couponCode);

  const [discount, setDiscount] = useState(0);

  // Handle coupon code input
  const handleCouponCodeChange = (e) => {
    setSelectedCoupon((prevData) => {
      return {
        ...prevData,
        couponCode: e.target.value
      }
    });
  };


  const [couponStatus, setCouponStatus] = useState("")

  const applyCoupon = async () => {
    try {
      console.log("jhala")
      if (!selectedCoupon.couponCode) {
        setCouponStatus("Coupon is Not Applied");
        return
      }

      const amountOfProductCart = products[0]?.total

      const applyCouponRes = await axios.post(
        `${apiUrl}/coupons/apply/coupon`,
        { couponCode: selectedCoupon.couponCode, cartAmount: amountOfProductCart },
        {
          withCredentials: true
        }
      )
      console.log(applyCouponRes)
      if (applyCouponRes.data.success) {
        const matchedCoupon = applyCouponRes.data.coupon;
        if (matchedCoupon) {
          console.log("hire", matchedCoupon)
          if (matchedCoupon.discountType === "percentage_discount") {
            setDiscount((matchedCoupon?.amount / 100) * products[0]?.total);
          }
          else if (matchedCoupon.discountType === "fixed_card_discount") {
            setDiscount(matchedCoupon?.amount)
          }
          setCouponCode("")
          setCouponStatus("Coupon is Applied")
        } else {
          setCouponStatus("Coupon is Not Applied");
          console.log("Coupon not found");
        }
      }
      else {
        setDiscount(0)
        setCouponStatus("You are not eligible for this coupon based on cart amount");
      }
    } catch (error) {
      alert("server error while applying coupon")
    }
  };


  ////////////////////////////
  const [coupon, setCoupon] = useState({});
  const [selectedCoupon, setSelectedCoupon] = useState({});
  //  console.log("coupons:", coupons);

  const getCoupons = async () => {
    try {
      const productAmount = products[0]?.total
      const response = await axios.get(
        `${apiUrl}/coupons/allget?amount=${productAmount}`,
        {
          withCredentials:true
        }
      );

      if (response.data.success === true) {
        setCoupon(response.data.coupon[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    if (products.length !== 0) {
      getCoupons();
    }
  }, [products.length]);

  const handleCheckout = () => {
    const finalsubTotal = calculateSubTotal()
    const finalTotal = calculateTotal()

    dispatch(setSelectedCartItem(selectedProduct))
    dispatch(setCartAmountDetails({
      finalsubTotal,
      finalTotal,
      discount
    }))

    navigate(`/checkout`)
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#F1F0F1" }}>
      <TopBar />
      <div style={{ marginTop: 40 }} className="tableCartBox">
        <div>
          {products.length > 1 && (
            <p style={{ color: "red", fontSize: "90%" }}>
              ( Note: Please add only one product to the cart at a time. )
            </p>
          )}
          <Table>
            <Thead>
              <Tr style={{ backgroundColor: "white", height: "50px" }}>
                <Th style={{ fontSize: 20, fontWeight: "bold", paddingLeft: "2%", textAlign: "left" }}>
                  REMOVE
                </Th>
                <Th style={{ fontSize: 20, fontWeight: "bold", paddingLeft: "2%", textAlign: "left" }}>
                  IMAGE
                </Th>
                <Th style={{ fontSize: 20, fontWeight: "bold", paddingLeft: "2%", textAlign: "left" }}>
                  Vehicle Type
                </Th>
                <Th style={{ fontSize: 20, fontWeight: "bold", paddingLeft: "2%", textAlign: "left" }}>
                  PRODUCT
                </Th>
                <Th style={{ fontSize: 20, fontWeight: "bold", textAlign: "left", paddingLeft: "2%" }}>
                  PRICE
                </Th>
                <Th style={{ fontSize: 20, fontWeight: "bold", textAlign: "left", paddingLeft: "2%" }}>
                  SUBTOTAL
                </Th>
                <Th style={{ fontSize: 20, fontWeight: "bold", paddingLeft: "2%", textAlign: "left" }}>
                  SELECT
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {products.map((product, index) => (
                <Tr key={product._id + index}>
                  {/* Remove Item */}
                  <Td
                    style={{ border: "0.1px solid grey", paddingTop: 15, paddingBottom: 15 }}
                    onClick={() => handleDelete(product._id)}
                  >
                    <img src={cancel} alt="Cancel" className="cancleimg" />
                  </Td>

                  {/* Product Image */}
                  <Td style={{ border: "0.1px solid grey", paddingTop: 10, paddingBottom: 10 }}>
                    <Link to={`/product/${product._id}`}>
                      <img
                        src={product.image ? require(`../../assests/${product.image}`) : "Loading Image..."}
                        alt={`Service Plan: ${product.product_name}`}
                        className="cartImg"
                      />
                    </Link>
                  </Td>
                  {/* vehicle type */}
                  <Td
                    style={{ fontSize: 20, fontWeight: "normal", paddingLeft: "2%", textAlign: "left", border: "0.1px solid grey", paddingTop: 15, paddingBottom: 15 }}
                  >
                    {product.vehicle_type}
                  </Td>
                  {/* Product Name */}
                  <Td
                    style={{ fontSize: 20, fontWeight: "normal", paddingLeft: "2%", textAlign: "left", border: "0.1px solid grey", paddingTop: 15, paddingBottom: 15 }}
                  >
                    {product.product_name}
                  </Td>

                  {/* Product Price */}
                  <Td
                    style={{ fontSize: 20, fontWeight: "normal", paddingLeft: "2%", textAlign: "left", color: "rgb(46,85,162)", border: "0.1px solid grey", paddingTop: 15, paddingBottom: 15 }}
                  >
                    {product.price_tag}
                  </Td>

                  {/* Subtotal */}
                  <Td
                    style={{ fontSize: 20, fontWeight: "normal", paddingLeft: "2%", textAlign: "left", color: "rgb(46,85,162)", border: "0.1px solid grey", paddingTop: 15, paddingBottom: 15 }}
                  >
                    ₹ {product.total}
                  </Td>
                  {/* Single Selection Radio Button */}
                  <Td style={{ border: "0.1px solid grey", textAlign: "center" }}>
                    <input
                      type="radio"
                      name="selectedProduct"
                      checked={selectedProductId === product._id}
                      onChange={() => handleSelection(product._id)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>

        <div
          style={{
            width: "100%",
            margin: "auto",
            marginTop: 50,
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "40%" }}>
            <div style={{ margin: "1rem 0", display: "flex", flexDirection: "column", gap: "0.5rem", alignItems: "center" }}>
              <div><span style={{ fontSize: "1.2rem" }}>Your Coupon</span></div>
              <CouponCodeCard coupon={coupon} setSelectedCoupon={setSelectedCoupon} />
            </div>
            <div>
              <input
                style={{
                  width: "45%",
                  border: "0.5px solid rgb(221,221,221)",
                  margin: "1% 2%",
                  borderRadius: "10px",
                }}
                placeholder="Coupon code"
                value={selectedCoupon?.couponCode}
                onChange={handleCouponCodeChange}
              />
              <button className="applyBtn" onClick={applyCoupon} disabled={!coupon || Object.keys(coupon).length === 0}>
                APPLY COUPON
              </button>
            </div>
            <p
              style={{
                textAlign: 'center',
                color: couponStatus === "Coupon is Applied" ? "green" : "red",
              }}
            >
              {couponStatus}
            </p>

          </div>

          <div
            style={{
              width: "54%",
            }}
          >
            <p
              style={{
                textAlign: "center",
                backgroundColor: "white",
                padding: "1%",
                fontSize: "90%",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              CART TOTALS
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: -15,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  padding: "0 5%",
                  width: "30%",
                }}
              >
                Subtotal
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "rgb(46,85,162)",
                  marginLeft: 20,
                }}
              >
                ₹ {calculateSubTotal()}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: -15,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  padding: "0 5%",
                  width: "30%",
                }}
              >
                Discount
              </p>
              <p
                style={{
                  fontSize: 18,
                  color: "red",
                  marginLeft: 20,
                  marginTop: 22,
                }}
              >
                - ₹ {discount}{" "}
                <span
                  style={{
                    fontSize: 15,
                    color: "grey",
                  }}
                >
                  (Applied Coupon)
                </span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: -15,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 30,
                  padding: "0 5%",
                  width: "30%",
                }}
              >
                Total
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 30,
                  color: "rgb(46,85,162)",
                  marginLeft: 20,
                }}
              >
                ₹ {Math.floor(calculateTotal())}/-
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <button
                className="checkoutbtn"
                onClick={handleCheckout}
                disabled={products.length === 0}
                style={{
                  cursor: products.length === 0 ? "not-allowed" : "pointer",
                }}
              >
                PROCEED TO CHECKOUT
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
