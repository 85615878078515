import React, { useEffect, useState } from "react";
import "./orderConfirmation.css";
import { TopPage } from "../TopPage/TopPage";
import { FooterHomeSecond } from "../FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";
import gmilogo from "../../assests/gmi-logo.png";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

export const OrderConfirmation = () => {
  const location = useLocation()
  console.log(location)

  const { orderPlacedData } = useSelector((state) => state.cart)

  console.log("orderPlacedData", orderPlacedData)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <TopBar />
      <div className="OrderContainer">
        <div>
          <img
            className="confirmImg"
            src="https://img.freepik.com/free-vector/male-mechanic-repairs-motorcycle-wheel-isolated-white-background-man-fixing-motorbike-repair-shop-garage-concept-motor-service-repair-maintenance-garage_575670-1889.jpg?w=740&t=st=1703053257~exp=1703053857~hmac=643de7a974be94ae22b20bbd07cad6edd2fe4815669d178c68360956998d8e06"
            alt="bikeBook"
          />
        </div>
        <div style={{ width: "90%", margin: "auto" }}>
          <p className="textOrder">
            {/* Thank You, Order Received, Pickup boy will Arrived Soon */}
            Thank you for shopping with us at GreaseMonkey. Your order no. {orderPlacedData.OID} is confirmed and we will shortly send further details.
          </p>
        </div>
        <div style={{}}>
          <h2 style={{ textAlign: "center" }}>Your Order Details</h2>
          <div
            style={{
              overflowX: "auto",
              width: "100%",
              margin: "20px 0",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #ddd",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    Order ID
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {orderPlacedData.OID || ""}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    Product Name
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {Object.keys(orderPlacedData).length !== 0 ? orderPlacedData?.productsBuyed[0]?.product_name : ""}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    Status
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    Booking Confirmed
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    Vehicle Type
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {
                      orderPlacedData.vehicleType
                    }
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    Registration Number
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {
                      orderPlacedData.registrationNumber
                    }
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    Vehicle Make Model
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {
                      orderPlacedData.makeModel
                    }
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    City
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {
                      orderPlacedData.city
                    }
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    Pickup Location
                  </td>
                  <td
                    style={{
                      padding: "12px",
                      border: "1px solid #ddd",
                    }}
                  >
                    {
                      orderPlacedData.pickupLocation
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div >
        </div >
      </div >
      <FooterHomeSecond />
    </div >
  );
};

