import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCartData: {},
    cartAmountDetails: {},
    selectedProductData: {},
    selectedProductId:"",
    orderPlacedData:{}
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setSelectedCartItem: (state, action) => {
            state.selectedCartData = action.payload
        },
        setCartAmountDetails: (state, action) => {
            state.cartAmountDetails = action.payload
        },
        setSelectedProductDetails: (state, action) => {
            state.selectedProductDetails = {...state.selectedProductDetails,...action.payload}
        },
        setSelectedProductId:(state,action)=>{
            state.selectedProductId = action.payload
        },
        setOrderPlacedData:(state,action)=>{
            state.orderPlacedData = action.payload;
        }
    }
})


export const { setSelectedCartItem, setCartAmountDetails, setSelectedProductDetails, setSelectedProductId,setOrderPlacedData } = cartSlice.actions;
export default cartSlice.reducer;