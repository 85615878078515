import React from 'react';
import './couponCodeCard.css'; // Import the CSS file

const CouponCodeCard = ({ coupon, setSelectedCoupon }) => {

    const handleCouponCodeClicked = () => {
        if(coupon){
            setSelectedCoupon(coupon)
        }else{
            alert("Sorry, you are not eligible for this coupon.")
        }
    }
    return (
        <div className="coupon_code_wrapper" onClick={handleCouponCodeClicked}>
            <div className="my_discount_amount">
                <span className='rupees'>
                    {coupon?.discountType === "fixed_card_discount" ? "₹" : ""}
                    {coupon?.amount}
                    {coupon?.discountType === "percentage_discount" ? "%" : ""}</span>
                <span className='discount_title'>Cart Discount</span>
            </div>
            <p className="my_coupon_code">{coupon?.couponCode}</p>
        </div>
    );
};

export default CouponCodeCard;