import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ServicePlan.css";
import { apiUrl } from "../../api/apiUrl";

export const ServicePlan = () => {
  let navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/mainproducts/allproducts`
      );
      setPlan(response.data);
    } catch (err) {
      console.error("Error fetching data:", err.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="planBox">
      <div>
        <h2 style={{ fontSize: 25, fontWeight: "bold", textAlign: "center" }}>
          Our Servicing Plans
        </h2>
      </div>
      <div>
        {isLoading && (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        )}
        {!isLoading && plan.length === 0 && <p>No service plans available.</p>}
        {!isLoading && plan.length > 0 && (
          <div className="planContainer">
            {plan.map((item) => (
              <div key={item._id} className="planItem">
                <div>
                  <img
                    src={
                      item.image
                        ? require(`../../assests/${item.image}`)
                        : "Loading Image..."
                    }
                    alt={`Service Plan: ${item.product_name}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                <div
                  style={{ marginTop: 10, textAlign: "center" }}
                  className="BookBtn"
                  onClick={() => navigate("/shop", { state: { item } })}
                >
                  <p
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: 14,
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    Book Now
                  </p>
                </div>
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "grey",
                    textAlign: "center",
                    margin: 7,
                  }}
                >
                  {item.category}
                </div>
                <div
                  style={{
                    fontSize: 20,
                    // height: "15%",
                    fontWeight: "bold",
                    color: "black",
                    textAlign: "center",
                    margin: 7,
                    // border:'1px solid red'
                  }}
                >
                  {item.product_name}
                </div>

                <div>
                  {item.description.map((item, index) => (
                    <div key={index} style={{ lineHeight: 1.3 }}>
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        •{" "}
                      </span>{" "}
                      <span className="planDesc">{item} </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
