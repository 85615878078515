import React, { useEffect, useState } from "react";
import axios from "axios";
import "./OrderPage.css";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { TopBar } from "../../TopBar/TopBar";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import OrderDetailsModal from "./OrderDetailsModal";
import { apiUrl } from "../../api/apiUrl";

export const OrderPage = () => {

  const [myOrdersData, setMyOrdersData] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);

  const fetchCustomersAllOrders = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/customer/fetch/customers/orders`,
        {
          withCredentials: true
        }
      );
      if (response.data.success) {
        setMyOrdersData(response.data.orders);
      }
    } catch (error) {
      console.error("Failed to fetch customer data1: Error", error.message);
    }
  };

  useEffect(() => {
    fetchCustomersAllOrders();
  }, []);

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "f0f0f0" }}>
      <TopBar />
      <div className="myAccountMainContainer">
        <SideMyAccount />
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: "150%", marginLeft: 20, fontWeight: "bold" }}>
            My Order
          </p>
          <div>
            <Table style={{ margin: "auto", width: "90%" }}>
              <Thead>
                <Tr>
                  <Th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "white",
                      height: 50,
                      textAlign: "left",
                      paddingLeft: 15,
                    }}
                  >
                    ORDER
                  </Th>
                  <Th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "white",
                      height: 50,
                      padding: 15,
                    }}
                  >
                    PRODUCT
                  </Th>
                  <Th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "white",
                      height: 50,
                      padding: 15,
                    }}
                  >
                    PAYMENT STATUS
                  </Th>
                  <Th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "white",
                      height: 50,
                      padding: 15,
                    }}
                  >
                    ORDER STATUS
                  </Th>
                  <Th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "white",
                      height: 50,
                      padding: 15,
                    }}
                  >
                    TOTAL
                  </Th>
                  <Th
                    style={{
                      border: "1px solid black",
                      backgroundColor: "white",
                      height: 50,
                      padding: 15,
                    }}
                  >
                    ACTIONS
                  </Th>
                </Tr>
              </Thead>
              {Array.isArray(myOrdersData) && myOrdersData.length > 0 ? (
                myOrdersData.map((order, index) => (
                  <Tbody key={index}>
                    {order.productsBuyed.map((product, index) => (
                      <Tr key={index}>
                        <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                          {order.OID}{" "}
                        </Td>
                        <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                          {product.product_name}
                        </Td>
                        <Td style={{ border: "0.1px solid grey", padding: 15, textTransform: "capitalize" }}>
                          {
                            order?.paymentStatus
                          }
                        </Td>
                        <Td style={{ border: "0.1px solid grey", padding: 15, textTransform: "capitalize" }}>
                          {
                            order.status === "new" ? "Boooking Confirmed" : order.status
                          }
                        </Td>
                        <Td style={{ border: "0.1px solid grey", padding: 15 }}>
                          ₹{order.totalSellingPrice || "---"}/-
                        </Td>
                        <Td style={{ display: "flex", gap: "1rem", border: "0.1px solid grey", padding: 15 }}>
                          <button onClick={() => handleViewDetails(order)}
                          >
                            View Details
                          </button>
                          <button style={{
                            backgroundColor: (order.status === "inTransitDown" && order.paymentStatus === "requested") ? "blue" : "gray",
                            color: "white", borderRadius: "0.2rem"
                          }}
                            disabled={!(order.status === "inTransitDown" && order.paymentStatus !== "requested")}>
                            Pay Now
                          </button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                ))
              ) : (
                <p>No orders available</p>
              )}
            </Table>
          </div>

          {/* Modal to show detailed product information */}
          {selectedOrder && (
            <OrderDetailsModal selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} />
          )}
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
