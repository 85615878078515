import React, { useEffect, useState } from "react";
import image from "../../assests/main-background.png";
import banner1 from "../../assests/banner1.png";
import { Link } from "react-router-dom";
import "./ServiceBook.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import generalservice from '../../assests/General Service.png'
import specialservice from "../../assests/Special Service.png";
import callnow from "../../assests/callnow.jpg";
import breakdownservice from "../../assests/Breakdown Service.png";
import insurence from "../../assests/Insurance.png";
import rto from "../../assests/RTO.png";
import car1 from "../../assests/car 1.png";
import bike1 from "../../assests/bike 1.png";
import yewarlypackeage from "../../assests/Yearly package.jpg";
import jumpstart from "../../assests/js.jpg";
import rotatedLogo from "../../assests/rotatedLogo.png";

import { ServicePlan } from "../ServicePlan/ServicePlan";

export const ServiceBook = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const words = ["Innovative", "Reliable", "Trustworthy"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % words.length);
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, [words]);

  ///// animation >>>>>>>>>>
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    // Use setTimeout to delay setting animationComplete to true
    // This will trigger the animation after a short delay
    setTimeout(() => {
      setAnimationComplete(true);
    }, 100);
  }, []);

  ///// animation  <<<<<<<<<<<<<<<<<<<<<


  return (
    <div className="mainservBox">
      <div
        className="hero-section"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="desktopStyles">
          <div className="tagline-container">
            <p className="tagLineText  ">{words[currentIndex]}</p>
          </div>
          <Link to={"/shop"} className="bookNowBtn flashy">
            Book Now
          </Link>
        </div>

        <div
          className={`rightDiv ${animationComplete ? "rightDivAnimation" : ""}`}
        >
          <div className="bannerDiv">
            <img src={banner1} alt="banner" className="bannerImage" />
          </div>
        </div>
      </div>

      <div className="smallContainer">
        <div className="serviceContainer">
          <div>
            <h2 className="servicemainTitle">Vehicle Services We Offer</h2>
          </div>
          <div className="services">
            <div className="flexbox">
              <a href="/shop">
                <div className="serviceItem">
                  <div>
                    <img
                      className="serviceImg"
                      alt="service logo"
                      src={generalservice}
                    />
                  </div>
                  <div>
                    <h3 className="serviceTitle">Basic Service</h3>
                  </div>
                </div>
              </a>

              <a href="/shop">
                <div className="serviceItem">
                  <div>
                    <img
                      className="serviceImg"
                      alt="service logo"
                      src={specialservice}
                    />
                  </div>
                  <div>
                    <h3 className="serviceTitle">Pro<br></br>Service</h3>
                  </div>
                </div>
              </a>
            </div>

            {/* <div> */}
            <a href="tel:+91-9960445033">
              <div className="serviceItem">
                <div>
                  <img
                    className="serviceImg serviceMiddleImg"
                    alt="service logo"
                    src={breakdownservice}
                  />
                </div>
                <div>
                  <h3 className="serviceTitle">Breakdown Assistance</h3>
                </div>
              </div>
            </a>
            {/* </div> */}

            <div className="flexbox">
              <div className="serviceItem">
                <div>
                  <img
                    className="serviceImg"
                    alt="insurence logo"
                    src={insurence}
                  />
                </div>
                <div>

                  <h3 className="serviceTitle">Insurance Renewals</h3>
                </div>
              </div>
              <div className="serviceItem">
                <div>
                  <img className="serviceImg" alt="rto logo" src={rto} />
                </div>
                <div>
                  <h3 className="serviceTitle">RTO Assistance</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subscriptionContainer">
          <div>
            <h2 className="subscriptionTitle">Buy a Subscription Plan</h2>
          </div>
          <div className="subscriptionImages">
            <img className="multiVehicleImg" alt="bike1 logo" src={bike1} />

            <img className="multiVehicleImg" alt="car1 logo" src={car1} />
          </div>
        </div>
        <div className="subscriptionContaine2">
          {/* <img
            src="https://i0.wp.com/greasemonkeyindia.com/wp-content/uploads/2023/12/Winter-carnival-new.png"
            className="additionalImages"
            alt="service logo"
          /> */}
          <Slider {...settings}>
            <div>
              <img
                className="additionalImages"
                src={jumpstart}
                alt="Image 1"
              />
            </div>
            <div>
              <img
                className="additionalImages"
                src={yewarlypackeage}
                alt="Image 2"
              />
            </div>
            <div>
              <a href="tel:+91-9960445033">
                <img
                  className="additionalImages"
                  src={callnow}
                  alt="Image 3"
                />
              </a>
            </div>
          </Slider>
        </div>
        <div className="rotedLogoBox">
          <img src={rotatedLogo} className="rotatedImage" alt="service logo" />
        </div>
      </div>
      {/* <ServicePlan /> */}
    </div>
  );
};
