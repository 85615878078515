import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUserAuthenticated: false,
    loggedInCutomerData: {}
}

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setUserAuthenticated: (state, action) => {
            state.isUserAuthenticated = action.payload
        },
        setLoggedInCustomerData: (state, action) => {
            state.loggedInCutomerData = action.payload
        }
    }
})


export const { setUserAuthenticated, setLoggedInCustomerData } = customerSlice.actions;
export default customerSlice.reducer;