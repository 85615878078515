import React, { useState, useEffect } from "react";
import axios from "axios";
import { SideMyAccount } from "../../components/MyAccount/SideMyAccount";
import { TopBar } from "../../TopBar/TopBar";
import { FooterHomeSecond } from "../../components/FooterHome/FooterHomeSecond";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedInCustomerData } from "../../features/customerSlice.features";
import { apiUrl } from "../../api/apiUrl";

export const EditAccount = () => {
  const dispatch = useDispatch();
  const { loggedInCutomerData } = useSelector((state) => state.customer);

  const [allOtherAddresses, setAllOtherAddresses] = useState([]);
  const [otherAddressData, setOtherAddressData] = useState({
    addressLabel: "",
    address: ""
  });

  // Store initial state for comparison
  const initialState = {
    name: loggedInCutomerData.name,
    email: loggedInCutomerData.email,
    phone_no: loggedInCutomerData.phone_no,
    dob: loggedInCutomerData.dob || "",
    gender: loggedInCutomerData.gender || "",
    address: loggedInCutomerData.address || "",
  };

  const [formCustomerData, setFormCustomerData] = useState(initialState);
  const [hasChanges, setHasChanges] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [oldVersionOfAllAddress, setOldVersionOfAllAddress] = useState([])

  // Handle Input Change and Check if Data is Modified
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormCustomerData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      // Check if the updated data is different from initial data
      const isModified = Object.keys(updatedData).some(
        (key) => updatedData[key] !== initialState[key]
      );
      setHasChanges(isModified);
      return updatedData;
    });
  };

  // Handle Form Submission - Sends request only if changes detected
  const confirmUpdate = async (e) => {
    e.preventDefault();

    if (!hasChanges) {
      alert("No changes detected.");
      return;
    }

    try {
      const response = await axios.patch(
        `${apiUrl}/customer/edit`,
        { updatedCustomerData: { ...formCustomerData, otherAddress: allOtherAddresses } },
        { withCredentials: true }
      );

      if (response.data.success) {
        alert(response.data.message);
        dispatch(setLoggedInCustomerData(response.data.customer));
        setHasChanges(false); // Reset change detection after update
      }
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

  const [updateStatus, setUpdateStatus] = useState(false);

  const clearOtherAddressData = () => {
    setOtherAddressData({
      addressLabel: "",
      address: ""
    });
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    if (!updateStatus) {
      setAllOtherAddresses((prevAddresses) => {
        return [...prevAddresses, otherAddressData];
      });
      clearOtherAddressData();
    } else {
      const updatedAllAddressData = allOtherAddresses.map((curAddress, index) => {
        if (index === editIndex) {
          return otherAddressData;
        }
        return curAddress;
      });
      setAllOtherAddresses(updatedAllAddressData);
      setUpdateStatus(false);
      clearOtherAddressData();
    }
  };

  const handleOtherAddressChanged = (e) => {
    setOtherAddressData((prevAddressData) => {
      return {
        ...prevAddressData,
        [e.target.name]: e.target.value
      };
    });
  };

  const handleAddressEdit = (index, curAddress) => {
    setOtherAddressData(curAddress);
    setUpdateStatus(true);
    setEditIndex(index);
  };

  const handleAddressDelete = (id) => {
    const updatedAllOtherAddress = allOtherAddresses.filter((curAddress, index) => {
      return index !== id;
    });
    setAllOtherAddresses(updatedAllOtherAddress);
  };

  const fetchCurrentCustomerData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/customer/fetch/single`,
        { withCredentials: true }
      )

      if (response.data.success) {
        dispatch(setLoggedInCustomerData(response.data.customer))
        const loggedInCutomerData = response.data.customer
        setFormCustomerData({
          name: loggedInCutomerData.name,
          email: loggedInCutomerData.email,
          phone_no: loggedInCutomerData.phone_no,
          dob: loggedInCutomerData.dob || "",
          gender: loggedInCutomerData.gender || "",
          address: loggedInCutomerData.address || "",
        })
      }
    } catch (error) {
      alert("server error while fetching current customer data !!")
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(loggedInCutomerData).length === 0 && loggedInCutomerData.constructor === Object) {
      fetchCurrentCustomerData()
    } else {
      setAllOtherAddresses(loggedInCutomerData.otherAddress)
      setOldVersionOfAllAddress(loggedInCutomerData.otherAddress)
    }
  }, [loggedInCutomerData]);

  useEffect(() => {
    if (JSON.stringify(allOtherAddresses) !== JSON.stringify(oldVersionOfAllAddress)) {
      setHasChanges(true);
    }
  }, [allOtherAddresses])

  return (
    <div style={{ backgroundColor: "#f0f0f0" }}>
      <TopBar />
      <div className="myAccountMainContainer">
        <SideMyAccount />
        <div style={{ width: "100%", margin: "30px 0" }}>
          <form onSubmit={confirmUpdate}>
            <div style={{ margin: "20px 0" }}>
              <label htmlFor="name">Full Name *</label>
              <input
                id="name"
                type="text"
                name="name"
                value={formCustomerData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              <label htmlFor="email">Email address *</label>
              <input
                id="email"
                type="email"
                name="email"
                value={formCustomerData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              <label htmlFor="phone_no">Mobile Number *</label>
              <input
                id="phone_no"
                type="tel"
                name="phone_no"
                value={formCustomerData.phone_no}
                onChange={handleInputChange}
                required
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "45%" }}>
                <label htmlFor="dob">Date of Birth</label>
                <input
                  id="dob"
                  type="date"
                  name="dob"
                  value={formCustomerData.dob}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div>
                <label className="label" htmlFor="gender">Gender:</label>
                <select
                  id="gender"
                  name="gender"
                  value={formCustomerData.gender}
                  onChange={handleInputChange}
                  className="gender-dropdown"
                  required
                >
                  <option value="" disabled>Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                  <option value="prefer not to say">Prefer not to say</option>
                </select>
              </div>
            </div>
            <div style={{ margin: "20px 0" }}>
              <label>Home Address</label>
              <input
                id="address"
                type="text"
                name="address"
                value={formCustomerData.address}
                onChange={handleInputChange}
                required
              />
            </div>

            <label htmlFor="email">Other Address</label>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div style={{ margin: "0 0" }}>
                <input
                  id="address"
                  type="text"
                  name="addressLabel"
                  value={otherAddressData.addressLabel}
                  onChange={handleOtherAddressChanged}
                  placeholder="Enter Address Label"
                />
              </div>
              <div style={{ margin: "0 0" }}>
                <input
                  id="address"
                  type="text"
                  name="address"
                  value={otherAddressData.address}
                  onChange={handleOtherAddressChanged}
                  placeholder="Enter your address"
                />
              </div>
              <div
                onClick={handleAddAddress}
                style={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  padding: "0.2rem 0.8rem",
                  borderRadius: "0.2rem",
                  cursor: "pointer",
                  alignItems: "center",
                  color: "white",
                  backgroundColor: "black",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "fit-content",
                }}
              >
                <span>{updateStatus ? "Update" : "Add"}</span>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                marginTop: "1rem",
                width: "100%"
              }}
            >
              {allOtherAddresses?.map((curAddress, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem", // Increased gap for better separation
                      backgroundColor: "#ffc90c",
                      borderRadius: "0.5rem", // Smoother rounded corners
                      padding: "1rem 1rem", // Slightly larger padding for balance
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "10rem",
                      flexDirection: "column",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow for depth
                    }}
                  >
                    <div
                      className="action_buttons_group"
                      style={{ display: "flex", gap: "0.4rem", justifyContent: "center" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "2rem",
                          height: "2rem", // Slightly bigger buttons for better touch targets
                          padding: "0.3rem",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#333", // Darker background for better contrast
                          borderRadius: "50%",
                          cursor: "pointer",
                          transition: "background-color 0.2s ease",
                        }}
                        onClick={() => handleAddressEdit(index, curAddress)}
                      >
                        <i
                          className="fi fi-rr-file-edit"
                          style={{
                            fontSize: "1.2rem",
                            color: "white",
                          }}
                        ></i>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "2rem",
                          height: "2rem",
                          padding: "0.3rem",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#d9534f", // Red color for delete action
                          borderRadius: "50%",
                          cursor: "pointer",
                          transition: "background-color 0.2s ease",
                        }}
                        onClick={() => handleAddressDelete(index)}
                      >
                        <i
                          className="fi fi-rs-trash"
                          style={{
                            fontSize: "1.2rem",
                            color: "white",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="address_label_and_input" style={{ flexGrow: 1 }}>
                      <div>
                        <span style={{ fontWeight: 600, fontSize: "1rem" }}>{curAddress.addressLabel}</span>
                      </div>
                      <div>
                        <span style={{ lineHeight: "1.5rem", fontSize: "0.875rem", textAlign: "center", color: "#555" }}>
                          {curAddress.address}
                        </span>
                      </div>
                    </div>
                  </div>

                );
              })}
            </div>

            <input
              type="submit"
              value={"Save Changes"}
              style={{
                backgroundColor: hasChanges ? "#FFC600" : "#ccc",
                color: "white",
                margin: "20px 0",
                fontWeight: "bold",
                fontSize: "95%",
                width: "fit-content",
                padding: "0.8rem 1rem",
                marginLeft: "37%",
                cursor: hasChanges ? "pointer" : "not-allowed",
              }}
              disabled={hasChanges ? false : true}
            />
          </form>
        </div>
      </div>
      <FooterHomeSecond />
    </div>
  );
};
