import React, { useState } from "react";

const OrderDetailsModal = ({ selectedOrder, setSelectedOrder }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleCloseDetails = () => {
        setSelectedOrder(null);
        setDropdownOpen(false);
    };

    const formatDateOnly = (dateString) => {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return "";
        }

        const formattedDate = date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });

        return formattedDate;
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return "";
        }

        const formattedDate = date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });

        return formattedDate;
    };

    return (
        <>
            <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }}>
                <div className="modal" style={{
                    width: '50%', 
                    maxWidth: '700px', 
                    margin: '0 auto', 
                    backgroundColor: '#fff', 
                    borderRadius: '8px', 
                    padding: '20px', 
                    overflowY: 'auto', 
                    maxHeight: '80vh', 
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
                }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '20px' }}>
                        <h2 style={{ fontSize: '1.5rem', fontWeight: '600' }}>Order Details</h2>
                        <h2
                            style={{
                                padding: "8px", 
                                cursor: "pointer", 
                                backgroundColor: 'black', 
                                color: 'white', 
                                borderRadius: '50%', 
                            }}
                            onClick={handleCloseDetails}
                        >
                            {/* Flaticon Cross Icon */}
                            <i className="fi fi-br-cross"></i>
                        </h2>
                    </div>

                    <div style={{ marginBottom: '15px' }}>
                        <p><strong>Product Name:</strong> {selectedOrder.productsBuyed[0].product_name}</p>
                        <p><strong>Category:</strong> {selectedOrder.productsBuyed[0].category}</p>
                    </div>

                    <div style={{ marginBottom: '15px', cursor: "pointer" }}>
                        {/* Flaticon Arrow Toggle */}
                        <strong onClick={() => setDropdownOpen(!dropdownOpen)} style={{ fontSize: '1rem', color: '#007bff', display: 'flex', alignItems: 'center' }}>
                            <img 
                                src={dropdownOpen 
                                    ? "https://cdn.iconscout.com/icon/free/png-256/arrow-down-69-458396.png" 
                                    : "https://cdn.iconscout.com/icon/free/png-256/arrow-right-81-458394.png"} 
                                alt="toggle description" 
                                style={{ marginRight: '5px', width: '15px', height: '15px' }}
                            />
                            {dropdownOpen ? " Hide Descriptions" : " Show Descriptions"}
                        </strong>
                        {dropdownOpen && (
                            <div style={{ marginTop: '10px', paddingLeft: '20px', fontSize: '0.9rem', color: '#555' }}>
                                {selectedOrder.productsBuyed[0].description.map((desc, index) => (
                                    <div key={index}> ▸ {desc.main_point}</div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div style={{ marginBottom: '15px' }}>
                        <p><strong>Status:</strong> Booking Confirmed</p>
                        <p><strong>Make & Model:</strong> {selectedOrder.makeModel}</p>
                        <p><strong>Vehicle No.</strong> {selectedOrder.registrationNumber}</p>
                        <p><strong>Final Total:</strong> ₹ {selectedOrder.totalSellingPrice || "---"}</p>
                    </div>

                    <div style={{ marginBottom: '15px' }}>
                        <p><strong>City:</strong> {selectedOrder.city}</p>
                        <p><strong>Address:</strong> {selectedOrder.address}</p>
                        <p><strong>Order Time:</strong> {formatDate(selectedOrder.createdAt)}</p>
                        <p><strong>Pickup Time:</strong> {formatDateOnly(selectedOrder.servicingDate)} Time: {selectedOrder.serviceTimeSlot}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderDetailsModal;
